/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import cn from 'classnames';
import { PRODUCT_TYPES, TOAST } from '@constants';
import {
	BaseFramePDPDetails,
	BaseFramePDPSidebar,
	BuildFlow,
	Carousel,
	ErrorBoundary,
	FakeRecommendationCarousel,
	ImageGallery,
	Modal,
	ProductView,
	StepTracker,
	VTO,
} from '@components';
import {
	BASE_FRAME_NAMES,
	BUILD_FLOW_STEP_DIRECTION,
	BUILD_FLOW_STEPS,
	CUSTOMER_JOURNEYS,
	FRAME_COLORS,
	generateSunglassImages,
	getVariantByOption,
	LARGE_WIDTH,
	useIsMobile,
	VTO_SRC,
} from '@utils/index';
import { baseFrameReducer, BaseFrameState } from '@utils/hooks/useBaseFrame';
import { useTailoredExperience } from '@services/contentful';
import { trackProductView } from '@services/analytics/trackers';
import { useDebounce, useHasMounted, useLockedBody } from '@utils/hooks';
import { useBFContext, useToastContext } from '@context';
import { CollectionListFields } from '@ts/contentful';
import { useLifeStyleImage } from '@services/shopify';
import styles from './BaseFramePDP.module.scss';

const YotpoReviews = dynamic(() => import('@components').then(mod => mod.YotpoReviews));

const BaseFramePDP = ({ journey, yotpo, seoMetadata = {} }) => {
	const {
		setCollectionListSlug,
		frame,
		frameColor,
		lensColor,
		setVariantImages,
		product,
		step,
		setFrameColor,
		isQuickAdd,
		setStepChange,
	} = useBFContext();
	const isMobile = useIsMobile({ maxWidth: LARGE_WIDTH });
	const isDoubleColImage = useFeatureIsOn('is-double-col-image');
	const isSingleColImage = useFeatureIsOn('is-single-col-image');
	const imageLayoutType = isDoubleColImage ? 'double' : isSingleColImage ? 'single' : 'none';

	const { query, push } = useRouter();
	const [selectedImgIndex, setSelectedImgIndex] = useState(0);
	const isStickySidebar = useFeatureIsOn('is-sticky-right-rail');

	const hasMounted = useHasMounted();
	const { showToast } = useToastContext();
	const isEditMode = !isQuickAdd ? Boolean(query?.edit) : Boolean(query?.edit) && !Boolean(query?.quickAdd);

	// Product Info
	const { name, tags, type, variantImages } = product;

	const selectedVariant = getVariantByOption(product, frameColor);

	const initialVtoState: BaseFrameState = {
		product: product,
		variant: product.variants[0],
		frame: product.variants[0].option as FRAME_COLORS,
		lens: null,
		image: product.variants[0].image.url,
	};

	const isMetalFrameColor = [FRAME_COLORS.MIX_BLACK_GOLD, FRAME_COLORS.MIX_CRYSTAL_SILVER].includes(frameColor);
	const productVideo = selectedVariant?.metafields?.productVideo;
	const [FitMix, setFitMix] = useState(typeof window === 'undefined' ? null : window?.FitMix);
	const [vtoState, setVtoState] = useReducer(baseFrameReducer, initialVtoState);
	const [selectedMaterial, setSelectedMaterial] = useState(isMetalFrameColor ? frameColor : 'Acetate');
	const [modalType, setModalType] = useState<'VTO' | 'Imagery'>('VTO');
	const [isModalOpen, setIsModalOpen] = useState(false);

	const SUNGLASSES_JOURNEY = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const showVideo = query.demo === 'women' && !SUNGLASSES_JOURNEY;

	const { data: tailoredExperience } = useTailoredExperience();
	if (!!tailoredExperience?.collectionList) {
		setCollectionListSlug((tailoredExperience.collectionList?.fields as CollectionListFields)?.slug);
	}

	const isBuildFlowOpen = isQuickAdd ? step > BUILD_FLOW_STEPS.CUSTOMIZE : step >= BUILD_FLOW_STEPS.LENS;
	const header = document.querySelector<HTMLElement>('section[class^="Header_container__"]');
	isBuildFlowOpen ? (header.style.display = 'none') : (header.style.display = '');

	const debouncedTrackProductView = useDebounce(trackProductView);
	useEffect(() => {
		debouncedTrackProductView({
			variant: selectedVariant,
			path: `/${journey}/${query.demo}/`,
		});
	}, [selectedVariant]);

	const sunglassImageCallback = useCallback(({ name, baseColor, lensColor, length }) => {
		return generateSunglassImages({ name, baseColor, lensColor, length });
	}, []);

	useLockedBody(isBuildFlowOpen);

	const { data: lifeStyleImages } = useLifeStyleImage({
		frameColor,
		frame: product.name as `The ${(typeof BASE_FRAME_NAMES)[number]}`,
		journey,
		enabled: true,
	});

	const frameImages = useMemo(() => {
		const images = SUNGLASSES_JOURNEY
			? sunglassImageCallback({ name, baseColor: frameColor, lensColor: lensColor, length: 3 })
			: variantImages[frameColor];

		return [...images, ...(lifeStyleImages?.carousel || [])];
	}, [product, frameColor, lensColor, lifeStyleImages]);

	useEffect(() => {
		setVariantImages(frameImages.slice(0, -1));
	}, [frameImages]);

	useEffect(() => {
		if (isEditMode) showToast(TOAST.EDIT_MODE);
	}, [isEditMode]);

	useEffect(() => {
		const header = document.querySelector<HTMLElement>('section[class^="Header_container__"]');
		isBuildFlowOpen ? (header.style.display = 'none') : (header.style.display = '');
	}, [step]);

	useEffect(() => {
		if (isMetalFrameColor) {
			setSelectedMaterial(frameColor);
		} else {
			setSelectedMaterial('Acetate');
		}
	}, [frameColor]);

	useEffect(() => {
		if (isQuickAdd && !query?.quickAdd && isBuildFlowOpen) {
			setStepChange(BUILD_FLOW_STEP_DIRECTION.PREV);
		}
	}, [isQuickAdd, query]);

	const Recommendations = useCallback(
		() => (
			<ErrorBoundary>
				<FakeRecommendationCarousel
					variant={selectedVariant}
					selectedFrame={frame}
					headingText={`Similar to ${name}`}
					productTypes={[PRODUCT_TYPES.BASE_FRAME]}
					demo={query.demo as string}
					withPadding={isStickySidebar}
				/>
			</ErrorBoundary>
		),
		[selectedVariant, frame, name, query.demo, isStickySidebar]
	);

	if (!hasMounted) return;

	return (
		<Modal open={isModalOpen} onOpenChange={setIsModalOpen}>
			{!isBuildFlowOpen ? (
				<ProductView.Root
					seo={{
						...seoMetadata,
						product: {
							...product,
							aggregateRating: yotpo,
							images: frameImages,
							suggestedGender: query.demo as string,
							variants: [selectedVariant],
						},
					}}
					isStickySidebar={isStickySidebar}
				>
					<ProductView.Images>
						<div className={cn({ [styles['left-rail-container']]: isQuickAdd && !isStickySidebar })}>
							<StepTracker />
							{(isSingleColImage || isDoubleColImage) && !isMobile ? (
								<ImageGallery
									images={frameImages}
									video={productVideo}
									name={name}
									product={product}
									tags={tags}
									type={type}
									variant={selectedVariant}
									imageLayoutType={imageLayoutType}
									VTODispatch={!SUNGLASSES_JOURNEY && setVtoState}
								/>
							) : (
								<Carousel
									images={frameImages}
									isModalOpen={isModalOpen}
									video={showVideo && productVideo}
									name={name}
									product={product}
									tags={tags}
									type={type}
									setModalType={setModalType}
									selectedImgIndex={selectedImgIndex}
									setSelectedImgIndex={setSelectedImgIndex}
									variant={selectedVariant}
									VTODispatch={!SUNGLASSES_JOURNEY && setVtoState}
									modalType={modalType}
								/>
							)}
						</div>

						{isStickySidebar && !isMobile && (
							<>
								<BaseFramePDPDetails journey={journey} selectedMaterial={selectedMaterial} padding='small' />
								<Recommendations />
							</>
						)}
					</ProductView.Images>
					<BaseFramePDPSidebar
						frameImages={frameImages}
						yotpo={yotpo}
						journey={journey}
						selectedMaterial={selectedMaterial}
						setSelectedMaterial={setSelectedMaterial}
						isStickySidebar={isStickySidebar}
					/>
					{(!isStickySidebar || isMobile) && (
						<>
							<BaseFramePDPDetails journey={journey} selectedMaterial={selectedMaterial} />
							<ProductView.Recommendations>
								<ErrorBoundary>
									<FakeRecommendationCarousel
										variant={selectedVariant}
										selectedFrame={frame}
										headingText={`Similar to ${name}`}
										productTypes={[PRODUCT_TYPES.BASE_FRAME]}
										demo={query.demo as string}
										withPadding={isStickySidebar}
									/>
								</ErrorBoundary>
							</ProductView.Recommendations>
						</>
					)}
					<ProductView.Reviews>
						<YotpoReviews product={product} />
					</ProductView.Reviews>
				</ProductView.Root>
			) : (
				<BuildFlow />
			)}
			{!SUNGLASSES_JOURNEY && modalType === 'VTO' && (
				<>
					<Modal.Content
						removePadding
						includeCloseButton={false}
						onCloseAutoFocus={e => e.preventDefault()}
						style={{ maxHeight: 'fit-content', maxWidth: '64rem' }}
					>
						<VTO
							FM={FitMix}
							state={vtoState}
							setState={setVtoState}
							pathInfo={`/${journey}/${query.demo}`}
							showBaseFrameOption={false}
							selectedMaterial={selectedMaterial}
							showMaterialOption
							setSelectedMaterial={setSelectedMaterial}
							setFrameColor={frameColor => {
								setFrameColor(frameColor);
								push({ query: { ...query, frameColor: encodeURI(frameColor as string) } }, undefined, {
									shallow: true,
								});
							}}
						/>
					</Modal.Content>
					<Script
						src={VTO_SRC}
						strategy='lazyOnload'
						onLoad={() => {
							setFitMix(window.FitMix);
						}}
					></Script>
				</>
			)}
		</Modal>
	);
};

export default BaseFramePDP;
